import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "unrankedContainer",
    "rankedContainer",
    "rankingInput",
    "unrankedChoiceTemplate",
    "rankedChoiceTemplate",
    "unrankedCount",
    "rankedCount",
  ];

  // --- Data Model Configuration ---
  static values = {
    choices: Array, // All possible choices [{id, text}, ...]
    ranked: Array, // Currently ranked choice ids in order
  };

  // Current dragged element tracking
  draggedElement = null;

  // --- Stimulus & Primary Methods ---
  connect() {
    this.setupDragAndDrop();
    this.refreshUI();
  }

  rankedValueChanged() {
    this.refreshUI();
  }

  refreshUI() {
    this.renderLists();
    this.#updateHiddenInputs();
    this.#updateCounts();
  }

  // --- Data Model Methods ---

  getUnrankedChoices() {
    const rankedIds = new Set(this.rankedValue);
    return this.choicesValue.filter((choice) => !rankedIds.has(choice.id));
  }

  addToRanking(choiceId, position) {
    let newRanked = [...this.rankedValue];
    newRanked.splice(position, 0, choiceId);
    this.rankedValue = newRanked;
  }

  removeFromRanking(choiceId) {
    this.rankedValue = this.rankedValue.filter((id) => id !== choiceId);
  }

  moveRankedItem(choiceId, newPosition) {
    let newRanked = [...this.rankedValue];
    const oldPosition = newRanked.indexOf(choiceId);
    if (oldPosition !== -1) {
      newRanked.splice(oldPosition, 1);
      newRanked.splice(newPosition, 0, choiceId);
      this.rankedValue = newRanked;
    }
  }

  // --- UI Event Handlers ---

  unrankChoice(event) {
    const choiceId = event.currentTarget.dataset.choiceId;
    this.removeFromRanking(choiceId);
    this.refreshUI();
  }

  // --- Drag and Drop UI Logic ---

  setupDragAndDrop() {
    this.unrankedContainerTarget.addEventListener(
      "dragstart",
      this.handleDragStart.bind(this)
    );
    this.rankedContainerTarget.addEventListener(
      "dragstart",
      this.handleDragStart.bind(this)
    );
    this.element.addEventListener("dragend", this.handleDragEnd.bind(this));
    document.addEventListener("dragover", this.handleDragOver.bind(this));
  }

  // DragStart from either list container
  handleDragStart(event) {
    if (event.target.draggable) {
      this.draggedElement = event.target;
      this.draggedElement.classList.add("opacity-50");
    }
  }

  // DragEnd over full container
  handleDragEnd() {
    if (!this.draggedElement) return;

    this.draggedElement.classList.remove("opacity-50");

    const dropIndex = Array.from(this.rankedContainerTarget.children).indexOf(
      this.draggedElement
    );
    if (dropIndex !== -1) {
      const choiceId = this.draggedElement.dataset.choiceId;
      const isFromUnranked = !this.rankedValue.includes(choiceId);

      if (isFromUnranked) {
        this.addToRanking(choiceId, dropIndex);
      } else {
        this.moveRankedItem(choiceId, dropIndex);
      }
    }

    this.draggedElement = null;
  }

  // DragOver entire document
  handleDragOver(event) {
    event.preventDefault();
    if (!this.draggedElement) return;

    const containerRect = this.rankedContainerTarget.getBoundingClientRect();
    const y = Math.min(
      Math.max(event.clientY, containerRect.top),
      containerRect.bottom
    );

    if (
      event.clientX >= containerRect.left &&
      event.clientX <= containerRect.right
    ) {
      const afterElement = this.#getElementAfterTheDrag(y);
      if (afterElement) {
        this.rankedContainerTarget.insertBefore(
          this.draggedElement,
          afterElement
        );
      } else {
        this.rankedContainerTarget.appendChild(this.draggedElement);
      }
    }
  }

  // --- UI Rendering ---

  renderLists() {
    this.unrankedContainerTarget.innerHTML = "";
    this.rankedContainerTarget.innerHTML = "";

    // Render ranked choices
    this.rankedValue.forEach((id, index) => {
      const choice = this.choicesValue.find((c) => c.id === id);
      if (choice) {
        this.rankedContainerTarget.appendChild(
          this.#createRankedChoiceElement(choice, index + 1)
        );
      }
    });

    // Render unranked choices
    this.getUnrankedChoices().forEach((choice) => {
      this.unrankedContainerTarget.appendChild(
        this.#createUnrankedChoiceElement(choice)
      );
    });
  }

  // --- Private UI Helper Methods ---

  #getElementAfterTheDrag(y) {
    const containerChildren = [...this.rankedContainerTarget.children].filter(
      (el) => el !== this.draggedElement
    );

    for (const child of containerChildren) {
      const box = child.getBoundingClientRect();
      const midPoint = box.top + box.height / 2;
      if (y <= midPoint) return child;
    }
    return null;
  }

  #createUnrankedChoiceElement(choice) {
    // Build it this way so container listeners can catch dragstart
    const div = document.createElement("div");
    div.draggable = true;
    div.dataset.choiceId = choice.id;

    const template = this.unrankedChoiceTemplateTarget.content.cloneNode(true);
    const content = template.firstElementChild;
    content.innerHTML = content.innerHTML.replace("${choiceText}", choice.text);

    div.appendChild(content);
    return div;
  }

  #createRankedChoiceElement(choice, rank) {
    // Build it this way so container listeners can catch dragstart
    const div = document.createElement("div");
    div.draggable = true;
    div.dataset.choiceId = choice.id;

    const template = this.rankedChoiceTemplateTarget.content.cloneNode(true);
    const content = template.firstElementChild;
    content.innerHTML = content.innerHTML
      .replace("${choiceId}", choice.id)
      .replace("${choiceText}", choice.text)
      .replace("${rank}", rank);

    div.appendChild(content);
    return div;
  }

  #updateCounts() {
    const unrankedCount = this.getUnrankedChoices().length;
    const rankedCount = this.rankedValue.length;
    this.unrankedCountTarget.textContent = unrankedCount;

    const completeClasses = ["text-white", "bg-success"];
    const incompleteClasses = ["text-muted", "bg-light"];

    if (unrankedCount === 0) {
      this.rankedCountTarget.classList.remove(...incompleteClasses);
      this.rankedCountTarget.classList.add(...completeClasses);
      this.rankedCountTarget.innerHTML = `<i class="fas fa-check ms-1"></i> ${rankedCount} `;
    } else {
      this.rankedCountTarget.classList.remove(...completeClasses);
      this.rankedCountTarget.classList.add(...incompleteClasses);
      this.rankedCountTarget.textContent = rankedCount;
    }
  }

  #updateHiddenInputs() {
    this.rankingInputTargets.forEach((input) => {
      input.value = "";
    });

    this.rankedValue.forEach((choiceId, index) => {
      if (this.rankingInputTargets[index]) {
        this.rankingInputTargets[index].value = choiceId;
      }
    });
  }
}
